
/* Hero Section */
#hero-section {
    background: #fffbf6;
  }
  .hero{
      background-color: #FFFBF6;
      width: 100%;
  }
.logo{
  height: 80px;
  width: 100px;
}
@media screen and (max-width: 766px) {
  .not-Responsive{
    display: none;
  }

}

@media screen and (min-width:767px ) {
  .Responsive{
    display: none;
  }
  .right-img{
    padding-top: 100px !important;
  }

}