.nav-tabs .nav-link:hover,.nav-tabs .nav-link:focus{
    border-color:#626C72 !important;

}
th{
    cursor: pointer;
}
.address-link{
    font-family: 'Poppins', sans-serif !important;
    font-weight: 300;
}
.addresmodal{
    min-height: 130px;
}