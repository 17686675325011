.a7a-col{
    height: 80vh;
    overflow-y: scroll;
    background-color: #fffbf6;
    border-radius: 10px 10px 10px 10px;
    -moz-border-radius: 10px 10px 10px 10px;
    -webkit-border-radius: 10px 10px 10px 10px;
    -webkit-box-shadow: -2px 3px 10px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -2px 3px 10px 0px rgba(0,0,0,0.75);
    box-shadow: -2px 3px 10px 0px rgba(0,0,0,0.75);
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

}
.a7a-col::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
@media only screen and (max-width:770px) {
    .add-col{
        padding-top: 10px;
    }
    .blyat{
        padding: 0!important;
    }
    .a7a-col{
        height: 69vh;
    }
}