@import url(https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css);
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");


a, a:hover, a:focus {
    text-decoration: none;
    transition: 0.5s;
    outline: none;
}

/* ------------ */

.card {
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    border-radius: 6px;
    margin-bottom: 30px;
    background-color: #fff;
    border: none;
}
.card-body {
    padding: 30px;
}

.form-submit{
  padding: 13px 30px;
  font-size: 15px;
  letter-spacing: 0.3px;
  font-weight: 400;
}
.card-header-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.prdct-qty-container {
    display: flex;
    align-items: center;
}

.qty-input-box {
    max-width: 50px;
    padding: 0px 5px;
    min-height: 40px;
    border-radius: 4px;
    text-align: center;
    display: inline-block;
    margin: 0px 5px;
    color: #475F7B;
    background-color: #FFF;
    border: 1px solid #DFE3E7;
    transition: 0.3s;
}

.qty-input-box:focus {
    color: #475F7B;
    background-color: #FFF;
    border-color: #DA5C40;
    outline: 0;
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 10%);
}

.prdct-qty-btn {
    width: 25px;
    height: 25px;
    border: none;
    border-radius: 4px;
    background-color: #FFA166 ;
    color: #FFF;
    font-size: 11px;
    transition: 0.3s;
}

.prdct-qty-btn:hover {
    background-color: #DA5C40;
    color: #fff;
}

.product-img img {
    width: 40px;
    height: 40px;
    border-radius: 4px;
}
.card-header-flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.prdct-delete {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 4px;
    background-color: #fde6e7;
    color: #ff5b5c;
    font-size: 15px;
    transition: 0.3s;
}

.prdct-delete:hover {
    background-color: #ff5b5c;
    color: #fff;
}

.cart-empty {
    text-align: center;
    padding: 30px;
}

.cart-empty i {
    font-size: 45px;
    color: #d5d6d8;
    margin-bottom: 10px;
    display: inline-block;
}

.cart-empty p {
    margin-bottom: 0;
    color: #bdbdbd;
    font-size: 16px;
}

.cart-table td, .cart-table th {
    vertical-align: middle;
}

tbody{
    overflow-x: scroll;
}

tbody::-webkit-scrollbar{
    width: 2px;
    height: 3px;
}
.table-responsive-xl::-webkit-scrollbar{
    width: 2px;
    height: 3px;
}

  table {
    border-collapse: collapse;
    width: 100%;
    border: none;
  }
  tr{
    border: none;
  }
  th, td {
    text-align: left;
    padding: 8px;
    border: none;
  }
  
  .sticky-cart {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 180px;

  }
  .disabled-div {
    display: none;
  }
  .totalpointdiv{
    background-color: #FFA166 !important;
    margin-top: 10px;
    color: #fff;
    margin: 0;
    padding: 4px;
  }
  .rewardpoint{
    font-size: 18px;
    font-weight: 400;
  }
  .loadingModal .modal-body{
    align-items: center;
}

.loadingModal.modal{
    --bs-modal-bg: unset !important;
}
