.contact4 {
  font-family: "Montserrat", sans-serif;
	color: #8d97ad !important;
  font-weight: 300;
}
.contact4 .right-image {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
}

.contact4.bg-info {
  background-image: url('../../assets/4438226.png'); 
  background-size: cover;
  background-repeat: no-repeat, repeat;
  padding: 0.75rem;
}

@media (min-width: 1024px) {
	.contact4 .contact-box {
    padding: 80px 105px 80px 0px;
	}
}

@media (max-width: 767px) {
	.contact4 .contact-box {
    padding-left: 15px;
    padding-right: 15px;
	}
}

@media (max-width: 1023px) {
	.contact4 .right-image {
			position: relative;
			bottom: -95px;
	}
}